import * as Ui from "components/ui";
import * as Layout from "components/layout";
import { Link } from "gatsby";
import React from "react";
import { graphql } from "gatsby";
import { PostTile, FeaturedPostTile } from "components/blog";
import { getTagMeta } from "../configs/blog-tags";

const BlogList = (props) => {
  const { data } = props;
  const { currentPage, numPages } = props.pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage =
    currentPage - 1 === 1
      ? "/blog"
      : "/blog/" + (currentPage - 1).toString() + "/";
  const nextPage = "/blog/" + (currentPage + 1).toString() + "/";
  const maxPages = 3;
  const tags = ["using-simplebackups", "guides", "news"];
  let metaPage = (currentPage > 1) ? "(" + currentPage + ")": "";

  return (
    <Layout.Layout>
      <Layout.SEO
        title={`SimpleBackups Blog - News, tips and tools ${metaPage}`}
        description={`Find out about our new releases, tips and tools to help you manage your server and database backups. ${metaPage}`}
      />
      <Layout.HeroEmpty />
      <Ui.Section>
        <Ui.Container>
          <div className="row mt-12">
            <div className="text-center md:mb-16">
              <h1 className="font-heading tracking-tight text-3xl md:text-6xl md:leading-tight font-black text-dark">
                The SimpleBackups Blog
              </h1>
              <Ui.Typography tag="h3">
                News, tips, guides and tools by the creators of SimpleBackups
              </Ui.Typography>
              <div className="flex gap-4 items-center justify-center mt-8">
                {tags.map((tag) => (
                  <a
                    href={`/blog/${tag}`}
                    className="px-6 py-2 bg-gray-lighter border border-indigo-100 rounded-lg hover:text-primary hover:translate-y-2 transition block font-medium text-sm"
                  >
                    {getTagMeta(tag).name}
                  </a>
                ))}
              </div>
            </div>

            <div className="mt-16 md:mt-32 mb-16 md:mb-32">
              <FeaturedPostTile
                post={data.lastPost.edges[0]}
              ></FeaturedPostTile>
            </div>
          </div>
        </Ui.Container>
      </Ui.Section>

      <Ui.Section className="bg-gray-lighter">
        <Ui.Container>
          <Ui.Row>
            <div className="grid md:grid-cols-3 gap-12">
              {data.posts.edges.map((post) => {
                return <PostTile post={post}></PostTile>;
              })}
            </div>

            <ul className="mt-12 flex flex-wrap list-none flex-row items-center justify-start p-0">
              {!isFirst && (
                <Link
                  to={prevPage}
                  rel="prev"
                  className="mr-2 text-base shadow-sm bg-white py-2 px-4"
                >
                  ← Previous Page
                </Link>
              )}
              {currentPage - maxPages > 0 && (
                <>
                  <li key={`pagination-number1`} className="m-0">
                    <Link
                      to={`/blog/`}
                      className="bg-white hover:bg-primary hover:text-white shadow-sm text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                    >
                      1
                    </Link>
                  </li>
                  {currentPage - 1 - maxPages > 0 && (
                    <li className="m-0">
                      <a
                        href="#"
                        className="bg-white hover:bg-primary shadow-sm hover:text-white text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                      >
                        ...
                      </a>
                    </li>
                  )}
                </>
              )}
              {Array.from({ length: numPages }, (_, i) =>
                i + 1 > currentPage + maxPages ||
                i + 1 < currentPage - maxPages ||
                (i === 0 && currentPage == 4) ? null : (
                  <li key={`pagination-number${i + 1}`} className="m-0">
                    {i + 1 != currentPage ? (
                      <Link
                        to={`/blog/${i === 0 ? "" : i + 1}/`}
                        className="bg-white hover:bg-primary shadow-sm hover:text-white text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                      >
                        {i + 1}
                      </Link>
                    ) : (
                      <span className="bg-primary text-white text-base shadow-sm font-medium py-2 px-4 rounded mr-1 transition">
                        {i + 1}
                      </span>
                    )}
                  </li>
                )
              )}
              {currentPage + maxPages < numPages && (
                <>
                  <li key={`pagination-number${numPages}`} className="m-0">
                    <a
                      href="#"
                      className="bg-white hover:bg-primary shadow-sm hover:text-white text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                    >
                      ...
                    </a>
                  </li>
                  <li key={`pagination-number${numPages}`} className="m-0">
                    <Link
                      to={`/blog/${numPages}/`}
                      className="bg-white hover:bg-primary shadow-sm hover:text-white text-base text-gray-800 font-medium py-2 px-4 rounded mr-1 transition"
                    >
                      {numPages}
                    </Link>
                  </li>
                </>
              )}
              {!isLast && (
                <Link
                  to={nextPage}
                  rel="next"
                  className="ml-2 text-base bg-white shadow-sm py-2 px-4"
                >
                  Next Page →
                </Link>
              )}
            </ul>
          </Ui.Row>
        </Ui.Container>
      </Ui.Section>
    </Layout.Layout>
  );
};

export const pageQuery = graphql`
  query BlogListQuery($skip: Int!, $limit: Int!) {
    posts: allMdx(
      filter: { frontmatter: { layout: { in: ["blog", "article"] } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            date(formatString: "MMMM D, YYYY")
            lastUpdateDate(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
    lastPost: allMdx(
      filter: { frontmatter: { layout: { in: ["blog", "article"] } } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
      skip: 0
    ) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            thumbnail
            date(formatString: "MMMM D, YYYY")
            lastUpdateDate(formatString: "MMMM D, YYYY")
          }
        }
      }
    }
  }
`;

export default BlogList;

export const frontmatter = {
  pageMetaData: {
    refKey: "blog",
    title: "Blog, news, tips and tools",
    description:
      "magically connect SimpleBackups to newly provisioned servers on AWS EC2, Amazon Lightsail, DigitalOcean, Laravel Forge, … etc. We call it a ‘stylish’ method because it eliminates the manual steps you need to do in order to create a backup. …",
    author: "simplebackups.com",
  },
};
